@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap');

*,
::after,
::before {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Lexend', sans-serif !important;
}
body {
    font-family: 'Lexend', sans-serif;
}